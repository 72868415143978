import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { ImageSearchOutlined } from '@mui/icons-material';
import { Cadastre } from '../../utlis/types';
import CadastreInfo from '../CadastreInfo/CadastreInfo';
import Loader from '../Loader/Loader';
import Toolbar from '../Toolbar/Toolbar';
import useStyles from './Content.styles';

interface ContentProps {
  loading: boolean;
  cadastre: Cadastre | null;
  assessment: number;
  cadastreImageUrl: string;
}

const Content = (props: ContentProps) => {
  const { loading, cadastre, cadastreImageUrl } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isMeterUnit, setIsMeterUnit] = useState<boolean>(true);

  const handleIsMeterUnitChange = (newIsMeterUnit: boolean) => {
    setIsMeterUnit(newIsMeterUnit);
  };

  if (loading) return <Loader />;

  return (
    <Box flexGrow={1}>
      {cadastre ? (
        <Box>
          <CadastreInfo {...props} isMeterUnit={isMeterUnit} />
          <Toolbar
            {...props}
            isMeterUnit={isMeterUnit}
            onIsMeterUnitChange={handleIsMeterUnitChange}
          />
          <Box className={classes.imageContainer}>
            {cadastreImageUrl.length ? (
              <img src={cadastreImageUrl} alt='Image of cadastre' className={classes.image} />
            ) : null}
          </Box>
        </Box>
      ) : (
        <Grid
          container
          alignItems={'center'}
          justifyContent={'space-around'}
          sx={{ height: '100%' }}
        >
          <Box sx={{ textAlign: 'center', height: 'fit-content', maxWidth: 600 }}>
            <ImageSearchOutlined color={'primary'} sx={{ fontSize: 80 }} />
            <Typography textAlign={'center'}>{t('silt_kiirpäring_selgitus')}</Typography>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default Content;

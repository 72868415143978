import React from 'react';
import { Box, Link } from '@mui/material';
import { MailOutline } from '@mui/icons-material';
import { ReactComponent as Logo } from '../../assets/logo-maaamet-est.svg';
import useStyles from './Footer.styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Logo className={classes.maaametIcon} />
      <Box className={classes.emailWrapper}>
        <MailOutline color={'primary'} />
        <Link href='mailto:katastertugi@maaamet.ee' className={classes.emailLink}>
          katastertugi@maaamet.ee
        </Link>
      </Box>
    </footer>
  );
};

export default Footer;

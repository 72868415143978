import { ReactNode } from 'react';

export interface SearchResult {
  host: string;
  addresses: Address[];
}

export interface Address {
  tunnus: string;
  taisaadress: string;
  boundingbox: string;
}

export interface Cadastre {
  assessment?: number;
  ayNimi: string;
  geom: GeomCoords;
  haritav: number;
  hkood: string;
  kinnistu: string;
  laadress: string;
  maksHind: number;
  marked: string;
  mets: number;
  mkNimi: string;
  moodust: string;
  mootja: string;
  muudet: string;
  muumaa: number;
  omviis: string;
  omvorm: string;
  ouemaa: number;
  ovNimi: string;
  pindala: number;
  regYhik: string;
  registr: string;
  rohumaa: number;
  ruumipind: number;
  siht1: string;
  siht2: string | null;
  siht3: string | null;
  soPrts1: number;
  soPrts2: number | null;
  soPrts3: number | null;
  timestamp: string;
  tunnus: string;
}

export interface GeomCoords {
  type: string;
  coordinates: number[][][];
}

export interface RowDef {
  key: string;
  getValue: (cadastre: Cadastre) => string | ReactNode;
}

export interface LandValue {
  assessment: number;
  cadastreId: string;
}

export interface LandParcels {
  haritav: number;
  mets: number;
  ouemaa: number;
  muumaa: number;
  rohumaa: number;
}

export enum LandParcelEnum {
  haritav = 'haritav',
  mets = 'mets',
  ouemaa = 'ouemaa',
  muumaa = 'muumaa',
  rohumaa = 'rohumaa',
}

export interface PdfPayload {
  cadastre: Cadastre;
  assessment: number;
}

export enum DownloadTypes {
  GML2 = 'GML_2',
  GML31 = 'GML_3_1',
  GML32 = 'GML_3_2',
  CSV = 'CSV',
  KML = 'KML',
  SHAPEFILE = 'SHAPEFILE',
  GEOJSON = 'GEOJSON',
}

export const IMAGE_HEIGHT = 250;
export const EDGE_RATIO = 2;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    padding: theme.spacing(0.5),
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  coloredRow: {
    backgroundColor: theme.palette.grey.A100,
  },
}));

export default useStyles;

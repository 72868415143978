import { getRandomBbox } from './helpers';
import {
  BASE_MAP_URL,
  CADASTRE_BE_URL,
  GSAVALIK_URL,
  INDADDRESS_BASE_URL,
  KY_PILT_URL,
  PDF_SERVICE_URL,
} from '../configs/url-config';
import { Cadastre, LandValue, PdfPayload, SearchResult } from './types';

const fetchCadastreById = async (id: string): Promise<Cadastre | undefined> => {
  try {
    const res = await fetch(`${CADASTRE_BE_URL}api/cadastre/${id}`);
    const json = await res.json();
    return json.message;
  } catch (e) {
    console.error(e);
  }
};

export const fetchLandValue = async (cadastreCode: string): Promise<LandValue | undefined> => {
  try {
    const response = await fetch(
      `${CADASTRE_BE_URL}api/mkhis/get-latest-assessment/${cadastreCode}`
    );
    const json = await response.json();
    return json.message;
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

export const fetchPdf = async (data: PdfPayload): Promise<Blob | undefined> => {
  try {
    const response = await fetch(`${PDF_SERVICE_URL}api/fast-search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.blob();
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

const fetchSearchByAddress = async (query: string): Promise<SearchResult | undefined> => {
  try {
    if (!query.length) return;

    const res = await fetch(
      `${INDADDRESS_BASE_URL}gazetteer?address=${query}&features=KATASTRIYKSUS`
    );
    return res.json();
  } catch (e) {
    console.error(e);
  }
};

const fetchSearchByCoordinates = async (
  x: number,
  y: number
): Promise<SearchResult | undefined> => {
  try {
    const res = await fetch(`${INDADDRESS_BASE_URL}gazetteer?x=${x}&y=${y}&features=KATASTRIYKSUS`);
    return res.json();
  } catch (e) {
    console.error(e);
  }
};

const fetchMapImage = async (cadastreCode: string): Promise<Blob> => {
  try {
    const response = await fetch(`${KY_PILT_URL}api/${cadastreCode}`);
    return response.blob();
  } catch (e) {
    console.error(e);
  }
  return new Blob();
};

const fetchBackgroundImage = async (): Promise<Blob> => {
  try {
    const { innerWidth, innerHeight } = window;
    const params = new URLSearchParams({
      service: 'WMS',
      version: '1.1.1',
      request: 'GetMap',
      format: 'image/png',
      srs: 'EPSG:3301',
      layers: 'CORINE,BAASKAART,KAART24,HALDUSPIIRID,TEED_VALGED,KYLAD,KAART24L',
      width: (innerWidth * 0.6).toString(),
      height: (innerHeight * 0.6).toString(),
      bbox: getRandomBbox(innerHeight, innerWidth),
    });
    const res = await fetch(BASE_MAP_URL + params);
    return res.blob();
  } catch (e) {
    console.error(e);
  }
  return new Blob();
};

const fetchCadastreFromGeoserver = async (
  cadastralCode: string
): Promise<Record<string, unknown> | undefined> => {
  try {
    const params = new URLSearchParams({
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      outputFormat: 'application/json',
      typeNames: 'maaamet:ky_kehtiv',
      cql_filter: `tunnus='${cadastralCode}'`,
    });
    const res = await fetch(GSAVALIK_URL + params);
    return res.json();
  } catch (e) {
    console.error(e);
  }
};

export {
  fetchCadastreById,
  fetchSearchByAddress,
  fetchSearchByCoordinates,
  fetchBackgroundImage,
  fetchMapImage,
  fetchCadastreFromGeoserver,
};

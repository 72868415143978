import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  searchPrompt: {
    textAlign: 'center',
  },
  imageContainer: {
    marginTop: '16px',
    width: '100%',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 300,
  },
}));

export default useStyles;

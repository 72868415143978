import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import proj4 from 'proj4';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { fetchSearchByAddress, fetchSearchByCoordinates } from '../../utlis/request-utils';
import { Address } from '../../utlis/types';
import { LEST_PROJECTION } from '../../utlis/helpers';
import { MyLocationOutlined } from '@mui/icons-material';

interface SearchProps {
  onSelectedAddressChange: (address: Address | null) => void;
}

const Search = ({ onSelectedAddressChange }: SearchProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Address[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getResults = async (value: string) => {
    const result = await fetchSearchByAddress(value);
    const addresses = result?.addresses;
    setOptions(addresses || []);
    setLoading(false);
  };

  const debounceOnChange = useCallback(debounce(getResults, 300), []);

  const searchByGeolocation = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(async (position: GeolocationPosition) => {
      const [x, y] = proj4(LEST_PROJECTION, [position.coords.longitude, position.coords.latitude]);
      const result = await fetchSearchByCoordinates(x, y);
      if (result?.addresses) {
        onSelectedAddressChange(result?.addresses[0]);
      }
      setLoading(false);
    });
  };

  const handleInputChange = (event: React.SyntheticEvent, newValue: string) => {
    setLoading(true);
    setInputValue(newValue);
    debounceOnChange(newValue);
  };

  const handleValueChange = (event: React.SyntheticEvent, newValue: Address | null) => {
    onSelectedAddressChange(newValue);
  };

  const renderStartAdornment = (params: AutocompleteRenderInputParams) => (
    <>
      <InputAdornment position='start'>
        {loading ? <CircularProgress size={16} sx={{ ml: 0.5 }} /> : null}
      </InputAdornment>
      {params.InputProps.startAdornment}
    </>
  );

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={10} sm={11}>
        <Autocomplete
          clearOnBlur={false}
          options={options}
          loading={loading}
          getOptionLabel={(option) => option.taisaadress}
          isOptionEqualToValue={(option, value) => option.tunnus === value.tunnus}
          filterOptions={(options: Address[]) => options}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleValueChange}
          size={'small'}
          loadingText={t('silt_kiirpäring_laadimine')}
          noOptionsText={t('silt_kiirpäring_andmed-puuduvad')}
          renderInput={(params) => (
            <TextField
              {...params}
              size={'small'}
              fullWidth
              placeholder={t('silt_kiirpäring_otsi')}
              InputProps={{
                ...params.InputProps,
                startAdornment: renderStartAdornment(params),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={2} sm={1}>
        <Grid container justifyContent={'center'}>
          <IconButton color={'primary'} onClick={searchByGeolocation}>
            <MyLocationOutlined />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Search;

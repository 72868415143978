import { format, isValid } from 'date-fns';
import et from 'date-fns/locale/et';
import { TFunction } from 'react-i18next';
import { Cadastre, LandParcels } from './types';
import { CADASTRE_BE_URL } from '../configs/url-config';

export const LEST_PROJECTION =
  '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';

export const composeDownloadUrl = (cadastreCode: string, downloadType: string) =>
  `${CADASTRE_BE_URL}api/cadastre-download?cadastralCode=${cadastreCode}&format=${downloadType}`;

export const formatAddress = (cadastre: Cadastre): string =>
  `${cadastre.laadress}, ${cadastre.ayNimi}, ${cadastre.ovNimi}, ${cadastre.mkNimi}`;

export const formatPurpose = (cadastre: Cadastre, t: TFunction): string => {
  const { siht1, siht2, siht3, soPrts1, soPrts2, soPrts3 } = cadastre;
  const purpose1 = t(siht1) + ' ' + soPrts1 + '%';
  const purpose2 = siht2 ? t(siht2) + ' ' + soPrts2 + '%' : null;
  const purpose3 = siht3 ? t(siht3) + ' ' + soPrts3 + '%' : null;
  return [purpose1, purpose2, purpose3].filter(Boolean).join(', ');
};

export const formatFormationDate = (cadastre: Cadastre): string => {
  const { registr } = cadastre;
  if (!registr) return '-';

  const date = new Date(registr);
  if (!isValid(date)) return registr;

  return `${format(date, 'do MMMM y', { locale: et })}.a.`;
};

export const insertAt = <T>(arr: T[], index: number, item: T): T[] => [
  ...arr.slice(0, index),
  item,
  ...arr.slice(index),
];

export const getRandomBbox = (height: number, width: number): string => {
  const [minX, minY, maxX, maxY] = [540000, 6450000, 560000, 6550000];
  const bboxMinX = getRandomNumberBetween(minX, maxX);
  const bboxMinY = getRandomNumberBetween(minY, maxY);
  return `${bboxMinX},${bboxMinY},${bboxMinX + width * 3},${bboxMinY + height * 3}`;
};

const getRandomNumberBetween = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const createImageUrl = async (fetchFunc: () => Promise<Blob>): Promise<string> => {
  const imageData = await fetchFunc();
  return imageData ? window.URL.createObjectURL(imageData) : '';
};

export const getLandParcelsFromFeatureCollection = (
  cadastre: Record<string, unknown>
): LandParcels | null => {
  const { features } = cadastre;
  if (!Array.isArray(features)) {
    return null;
  }
  const { properties } = features[0];
  return {
    mets: properties.mets,
    haritav: properties.haritav,
    ouemaa: properties.ouemaa,
    rohumaa: properties.rohumaa,
    muumaa: properties.muumaa,
  };
};

export const saveFile = (blob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

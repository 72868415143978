import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.grey.A100,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#FFF',
    }
  },
  meter: {
    marginRight: 0,
    borderRadius: '4px 0 0 4px'
  },
  hectare: {
    marginLeft: 0,
    borderRadius: '0 4px 4px 0'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  }
}));

export default useStyles;

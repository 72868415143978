import React from 'react';
import clsx from 'clsx';
import { ButtonBase } from '@mui/material';
import useStyles from './UnitButton.styles';

interface UnitButtonProps {
  isMeterUnit: boolean;
  onIsMeterUnitChange: (isMeter: boolean) => void;
}

const UnitButton = ({ isMeterUnit, onIsMeterUnitChange }: UnitButtonProps) => {
  const classes = useStyles();

  const handleSetMeter = () => {
    onIsMeterUnitChange(true);
  };

  const handleSetHectare = () => {
    onIsMeterUnitChange(false);
  };

  return (
    <>
      <ButtonBase
        className={clsx(classes.root, classes.meter, { [classes.selected]: isMeterUnit })}
        onClick={handleSetMeter}
      >
        &#13217;
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.root, classes.hectare, { [classes.selected]: !isMeterUnit })}
        onClick={handleSetHectare}
      >
        ha
      </ButtonBase>
    </>
  );
};

export default UnitButton;

import { createTheme } from '@mui/material/styles';

const primaryBlue = '#005AA3';
const darkBlue = '#004277';
const lightBlue = '#D0E1EE';

const theme = createTheme({
  palette: {
    primary: {
      main: primaryBlue,
      dark: darkBlue,
      light: lightBlue,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: primaryBlue,
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 12,
      },
    },
  },
});

export default theme;

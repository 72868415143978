import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Grid, Paper } from '@mui/material';
import { Address, Cadastre } from './utlis/types';
import {
  fetchBackgroundImage,
  fetchCadastreById,
  fetchLandValue,
  fetchMapImage,
} from './utlis/request-utils';
import { createImageUrl } from './utlis/helpers';
import Search from './components/Search/Search';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import Toast from './components/Toast/Toast';
import useStyles from './App.styles';

const App = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cadastreCode } = useParams();
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [cadastre, setCadastre] = useState<Cadastre | null>(null);
  const [assessment, setAssessment] = useState<number>(-1);
  const [cadastreImageUrl, setCadastreImageUrl] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setImageUrl(await createImageUrl(fetchBackgroundImage));
    })();
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      navigate(`/ky/${selectedAddress.tunnus}`);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (cadastreCode) {
      getCadastreById(cadastreCode);
      getAssessment(cadastreCode);
      getCadastreImageUrl(cadastreCode);
    }
  }, [cadastreCode]);

  const getCadastreById = async (cadastreCode: string) => {
    setLoading(true);
    const cadastre = await fetchCadastreById(cadastreCode);
    if (cadastre) {
      setCadastre(cadastre);
    } else {
      setAlertOpen(true);
    }
    setLoading(false);
  };

  const getCadastreImageUrl = async (cadastreCode: string) => {
    const url = await createImageUrl(() => fetchMapImage(cadastreCode));
    setCadastreImageUrl(url);
  };

  const getAssessment = async (cadastreCode: string) => {
    const landValue = await fetchLandValue(cadastreCode);
    if (!landValue) return;

    setAssessment(landValue.assessment);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;

    setAlertOpen(false);
  };

  return (
    <Box className={classes.root}>
      <Toast open={alertOpen} onClose={handleClose} title={t('error_kiirpäring_kyd-ei-leitud')} />
      <Box className={classes.backgroundImage} sx={{ backgroundColor: 'grey.200' }} />
      <Box
        className={classes.backgroundImage}
        sx={{
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
          opacity: imageUrl ? 1 : 0,
        }}
      />
      <Container maxWidth='md' className={classes.container}>
        <Grid component={Paper} className={classes.paper}>
          <Search onSelectedAddressChange={setSelectedAddress} />
          <Content
            loading={loading}
            cadastre={cadastre}
            assessment={assessment}
            cadastreImageUrl={cadastreImageUrl}
          />
          <Footer />
        </Grid>
      </Container>
    </Box>
  );
};

export default App;

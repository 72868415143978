import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }
}));

export default useStyles;

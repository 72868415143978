import React from 'react';
import { TFunction } from 'react-i18next';
import { CircularProgress, Link } from '@mui/material';
import { Cadastre, LandParcelEnum, RowDef } from '../../utlis/types';
import { formatAddress, formatFormationDate, formatPurpose } from '../../utlis/helpers';
import { E_LAND_REGISTER_URL } from '../../configs/url-config';

export const getRowDefs = (t: TFunction): RowDef[] => [
  {
    key: 'silt_kiirpäring_aadress',
    getValue: (cadastre: Cadastre) => formatAddress(cadastre),
  },
  {
    key: 'silt_kiirpäring_pindala',
    getValue: (cadastre: Cadastre) => cadastre.ruumipind.toString(),
  },
  {
    key: 'silt_kiirpäring_sihtotstarve',
    getValue: (cadastre: Cadastre) => formatPurpose(cadastre, t),
  },
  {
    key: 'silt_kiirpäring_moodustamise-aeg',
    getValue: (cadastre: Cadastre) => formatFormationDate(cadastre),
  },
  {
    key: 'silt_kiirpäring_omandivorm',
    getValue: (cadastre: Cadastre) => cadastre.omvorm,
  },
  {
    key: 'silt_kiirpäring_marked',
    getValue: (cadastre: Cadastre) => cadastre.marked,
  },
];

export const getLandValueRowDef = (assessment: number): RowDef => ({
  key: 'silt_kiirpäring_maa-väärtus',
  getValue: () =>
    assessment === -1 ? (
      <CircularProgress size={16} />
    ) : (
      `${assessment.toLocaleString('et') || '-'} €`
    ),
});

export const getPropertyNumberRowDef = (): RowDef => ({
  key: 'silt_kiirpäring_kinnistu-nr',
  getValue: (cadastre: Cadastre) => (
    <Link href={E_LAND_REGISTER_URL + cadastre.tunnus} target={'_blank'}>
      {cadastre.kinnistu}
    </Link>
  ),
});

export const getLandParcelsRowDefs = (t: TFunction, cadastre: Cadastre | null): RowDef[] =>
  cadastre
    ? Object.entries(cadastre)
        .filter(([key, value]) => Object.keys(LandParcelEnum).includes(key) && !!value)
        .map(([key, value]) => ({
          key,
          getValue: () => value.toString(),
        }))
    : [];

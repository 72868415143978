import React, { useMemo } from 'react';
import clsx from 'clsx';
import { TFunction, useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { Cadastre, LandParcelEnum, RowDef } from '../../utlis/types';
import { insertAt } from '../../utlis/helpers';
import {
  getLandParcelsRowDefs,
  getLandValueRowDef,
  getPropertyNumberRowDef,
  getRowDefs,
} from './row-defs';
import useStyles from './CadastreInfo.styles';

const AREA_KEY = 'silt_kiirpäring_pindala';

interface CadastreInfoProps {
  isMeterUnit: boolean;
  cadastre: Cadastre | null;
  assessment: number;
  cadastreImageUrl: string;
}

const getRows = (t: TFunction, assessment: number, cadastre: Cadastre | null): RowDef[] => {
  let rows = getRowDefs(t);
  let index = 4;
  const landParcelRows = getLandParcelsRowDefs(t, cadastre);
  landParcelRows.forEach((landParcelRow: RowDef, i: number) => {
    rows = insertAt(rows, i + 2, landParcelRow);
    index++;
  });
  rows = insertAt(rows, index, getLandValueRowDef(assessment));
  rows = insertAt(rows, index + 1, getPropertyNumberRowDef());
  return rows;
};

const getAreaWithUnit = (isMeterUnit: boolean, areaInMeters: string): string =>
  isMeterUnit
    ? `${(+areaInMeters).toLocaleString('et')} \u33A1`
    : `${(parseFloat(areaInMeters) / 10000).toFixed(2)} ha`;

const CadastreInfo = ({ isMeterUnit, cadastre, assessment }: CadastreInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const rows = useMemo(() => getRows(t, assessment, cadastre), [assessment, cadastre]);

  if (!cadastre) return null;

  return (
    <Box sx={{ m: 1 }}>
      <Typography variant={'h5'} sx={{ mb: 2 }}>
        {t('silt_kiirpäring_maa-info', { cadastreCode: cadastre.tunnus })}
      </Typography>
      {rows.map(({ key, getValue }: RowDef, index: number) => (
        <Grid
          container
          key={key}
          className={clsx(classes.row, {
            [classes.coloredRow]: !(index % 2),
          })}
        >
          <Grid item xs={4}>
            {t(key)}
          </Grid>
          <Grid item xs={8} textAlign={'right'}>
            {[AREA_KEY, ...Object.keys(LandParcelEnum)].includes(key)
              ? getAreaWithUnit(isMeterUnit, getValue(cadastre) as string)
              : getValue(cadastre)}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
export default CadastreInfo;

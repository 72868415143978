const {
  REACT_APP_FAST_SEARCH_URL,
  REACT_APP_CADASTRE_URL,
  REACT_APP_CADASTRE_BE_URL,
  REACT_APP_KY_PILT_URL,
  REACT_APP_GSAVALIK_URL,
  REACT_APP_PDF_SERVICE_URL,
} = process.env;

export const FAST_SEARCH_URL = REACT_APP_FAST_SEARCH_URL || 'https://kydev.kataster.ee/';
export const CADASTRE_URL = REACT_APP_CADASTRE_URL || 'https://minudev.kataster.ee/';
export const CADASTRE_BE_URL = REACT_APP_CADASTRE_BE_URL || 'https://cadastrebedev.kataster.ee/';
export const KY_PILT_URL = REACT_APP_KY_PILT_URL || 'https://kypiltdev.kataster.ee/';
export const PDF_SERVICE_URL = REACT_APP_PDF_SERVICE_URL || 'https://pdf-servicedev.kataster.ee/';
export const GSAVALIK_URL =
  REACT_APP_GSAVALIK_URL || 'https://gsavalik.envir.ee/geoserver/maaamet/ows?';
export const INDADDRESS_BASE_URL = 'https://inaadress.maaamet.ee/inaadress/';
export const BASE_MAP_URL = 'https://kaart.maaamet.ee/wms/kaart?';
export const E_LAND_REGISTER_URL =
  'https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?lang=Est&kadasterAadress=&nimi=&regNrIsikuKood=';
export const XGIS_URL =
  'https://xgis.maaamet.ee/xgis2/page/app/maainfo?lang=et&searchid=FUUKAT101x2&hide=true&TUNNUS=';

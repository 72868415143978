import React from 'react';
import { Alert, Snackbar } from '@mui/material';

interface ToastProps {
  open: boolean;
  onClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  title: string;
}

const Toast = ({ open, onClose, title }: ToastProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4500}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity='info' onClose={onClose}>
        {title}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

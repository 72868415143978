import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import { DownloadOutlined, MapOutlined, PrintOutlined, ShareOutlined } from '@mui/icons-material';
import { composeDownloadUrl, saveFile } from '../../utlis/helpers';
import { Cadastre, DownloadTypes } from '../../utlis/types';
import { fetchPdf } from '../../utlis/request-utils';
import { CADASTRE_URL, XGIS_URL } from '../../configs/url-config';
import { ReactComponent as XGisLogo } from '../../assets/X-GIS.svg';
import ToolbarButton from './ToolbarButton/ToolbarButton';
import Toast from '../Toast/Toast';
import UnitButton from './UnitButton/UnitButton';

interface ToolbarProps {
  cadastre: Cadastre | null;
  assessment: number;
  isMeterUnit: boolean;
  onIsMeterUnitChange: (isMeter: boolean) => void;
}

const Toolbar = ({ cadastre, assessment, isMeterUnit, onIsMeterUnitChange }: ToolbarProps) => {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setAlertOpen(true);
  };

  const handleNavigateToMap = () => {
    if (cadastre) {
      window.open(`${CADASTRE_URL}cadastre/${cadastre.tunnus}`, '_blank');
    }
  };

  const handleNavigateToXgis = () => {
    if (cadastre) {
      window.open(XGIS_URL + cadastre.tunnus, '_blank');
    }
  };

  const handlePrint = async () => {
    if (!cadastre) return;

    setLoading(true);
    const pdf = await fetchPdf({ cadastre, assessment });
    setLoading(false);
    if (!pdf) return;

    saveFile(pdf, `maatüki_väljavõte_${cadastre.tunnus}.pdf`);
  };

  const handleAlertClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;

    setAlertOpen(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (type?: string) => {
    setAnchorEl(null);
    if (type && cadastre) {
      window.open(composeDownloadUrl(cadastre.tunnus, type), '_blank');
    }
  };

  return (
    <Grid container>
      <Toast
        open={alertOpen}
        onClose={handleAlertClose}
        title={t('success_kiirpäring_kopeeritud')}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleMenuClose()}>
        {Object.values(DownloadTypes).map((type: string) => (
          <MenuItem key={type} onClick={() => handleMenuClose(type)}>
            {type}
          </MenuItem>
        ))}
      </Menu>
      <UnitButton isMeterUnit={isMeterUnit} onIsMeterUnitChange={onIsMeterUnitChange} />
      <ToolbarButton
        icon={<ShareOutlined />}
        onClick={handleCopyToClipboard}
        label={t('nupp_kiirpäring_kopeeri-url')}
      />
      {loading ? (
        <CircularProgress size={32} sx={{ margin: 1 }} />
      ) : (
        <ToolbarButton
          icon={<PrintOutlined />}
          onClick={handlePrint}
          label={t('nupp_kiirpäring_prindi')}
        />
      )}
      <ToolbarButton
        icon={<DownloadOutlined />}
        onClick={handleMenuOpen}
        label={t('nupp_kiirpäring_lae-alla')}
      />
      <ToolbarButton
        icon={<MapOutlined />}
        onClick={handleNavigateToMap}
        label={t('nupp_kiirpäring_näita-kaardil')}
      />
      <ToolbarButton
        icon={<XGisLogo style={{ width: 24, height: 24 }} />}
        onClick={handleNavigateToXgis}
        label={t('nupp_kiirpäring_xgis')}
      />
    </Grid>
  );
};

export default Toolbar;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  maaametIcon: {
    fill: theme.palette.primary.main,
    height: 60,
    [theme.breakpoints.up('sm')]: {
      height: 80,
    },
  },
  emailWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  emailLink: {
    paddingLeft: 8,
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
}));

export default useStyles;

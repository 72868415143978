import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

const Loader = () => (
  <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
    <CircularProgress />
  </Grid>
);

export default Loader;

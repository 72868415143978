import React, { ReactNode } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import useStyles from './ToolbarButton.styles';

interface ToolbarButtonProps {
  icon: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label?: string;
}

const ToolbarButton = ({ icon, onClick, label }: ToolbarButtonProps) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Tooltip title={label || ''}>
        <IconButton onClick={onClick} className={classes.root} aria-label={label}>
          {icon}
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default ToolbarButton;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    height: '100vh',
    width: '100vw',
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '24px 0',
    },
    padding: 0,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#FFF',
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    overflow: 'auto',
  },
  backgroundImage: {
    backgroundSize: 'cover',
    transition: 'opacity 0.3s ease-in',
    filter: 'blur(4px) brightness(0.9)',
    height: '100%',
    zIndex: -1,
    position: 'fixed',
    left: 0,
    right: 0,
  },
}));

export default useStyles;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import Loader from './components/Loader/Loader';
import theme from './muiTheme';
import './configs/i18n-config';

const Fallback = () => (
  <Box sx={{ height: '100vh' }}>
    <Loader />
  </Box>
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <Suspense fallback={<Fallback />}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<App />} />
              <Route path='/ky/:cadastreCode' element={<App />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
